@import "../../../../variables.scss";

@mixin buttons($background, $color, $borderC, $opacity) {
  background: $background;
  color: $color !important;
  border-color: $borderC;
  opacity: $opacity;
  &:focus,
  &:hover,
  &:active,
  &:active:focus {
    background: $background !important;
    color: $color !important;
    border-color: $borderC !important;
    box-shadow: none !important;
  }
  &.disable,
  &.disabled,
  &:disabled {
    opacity: $opacity;
    cursor: not-allowed;
  }
}

.Button {
  border-radius: $border-radius;
  border: 1px solid transparent;
  font-size: $font-size-200;
  font-weight: 400;
  font-family: $font-family-bold;
  padding: 0 1.875rem;
  height: 2rem;
  min-width: 100px;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:active,
  &:visited,
  &:focus {
    outline: none;
    box-shadow: none;
  }
  .fa {
    margin-left: initial;
  }
}

.PrimaryButton {
  @include buttons($red, $white, $red, 1);
  &:hover {
    @include buttons($red-1, $white, $red-1, 1);
  }
  &:active {
    @include buttons(darken($red-1, 3%), $white, darken($red-1, 3%), 1);
  }
  &.disable,
  &.disabled,
  &:disabled {
    @include buttons($red, $white, $red, 0.4);
  }
}

.SecondaryButton,
%SecondaryButton {
  @include buttons($gray-1, $red, $red, 1);
  &:hover {
    @include buttons(rgba($red, 0.12), $red, $red, 1);
  }
  &:active {
    @include buttons(rgba($red, 0.18), $red, $red, 1);
  }
  &.disable,
  &.disabled,
  &:disabled {
    @include buttons($gray-1, $red, $red, 0.4);
  }
}

.Icon {
  margin: 0 !important;
  margin-right: 1rem !important;
  font-size: 1rem;
  line-height: 1px;
}

.buttonDisabled {
  pointer-events: none;
  color: white !important;
  background: rgba(204, 31, 32, 0.7);
  border: none;
}
