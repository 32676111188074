@import "../../globals.module.scss";

$md-width: 700px;

.LandingPage {  
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 3.5rem 0;
  background-image: url('../../assets/images/backgroundLogo.png');
  background-size: cover;
  background-position: bottom;
  overflow: auto;
}

.Title {
  display: inline;
  font-size: 2rem;
  @media screen and (max-width: $md-width) {
    font-size: 1.5rem;
  }
}

.FlipContainer {
  display: flex;
  flex-flow: column;  
  height: 50%;
  justify-content: center;
  @media screen and (max-width: $md-width) {
    align-self: center;
    width: 100%;
    order: 1;
  }
}

.Flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
  @media screen and (max-width: $md-width) {
    height: 400px;
  }
}
