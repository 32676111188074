@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap");
@import "~bootstrap/scss/bootstrap.scss";
@import "./variables.scss";
@import "modules/shared/styles/table";
@import "modules/shared/styles/fonts";

body {
  margin: 0;
  padding: 0;
  font-family: $font-family-base;
  background-color: $gray-1;
  color: $secondary-color;
  letter-spacing: 0.031rem;
}

a {
  &:hover {
    text-decoration: none;
  }
}

body.ReactModal__Body--open {
  overflow: hidden;
}

.icon {
  margin: 0.5rem;
}

.pointer:hover {
  cursor: pointer;
}
.fa-angle-down:before {
  margin-left: -2em;
}

.collapse {
  list-style-type: none;
}

.z-index {
  z-index: 10000;
}

.sweet-alert {
  z-index: 100000;
}

.cross {
  padding: 0.5em;
  cursor: pointer;
}

.ExplorerTitle {
  font-size: 1.3rem;
  font-weight: 600;
}

.jumbotron {
  background: rgb(244, 250, 253);
}

#scn_testName {
  width: 100%;
  height: 2em;
}

.space {
  min-height: 25em;
}

.cursorPointer {
  cursor: pointer;
}

.Dropzone {
  border-style: dashed;
}

.ruby-text {
  display: ruby-text;
}

.h {
  border-style: solid;
}

.vspace {
  height: 4em;
}

.vspace10 {
  height: 10em;
}

.left {
  text-align: left;
}

#description {
  margin-top: 1em;
}

.fa {
  margin-left: -0.5em;
}

.icons {
  display: block;
  text-align: -moz-center;
}

h3 {
  text-align: left;
}

ul {
  list-style-type: none;
}

.iconLP {
  margin-bottom: 1em;
}

.iconLPI {
  margin-right: 1em;
}

#main-wrapper {
  width: 100% !important;
}

#main-wrapper > h1:nth-child(1) {
  height: auto !important;
}

.noScenariosFound {
  color: red;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

body > div.ReactModalPortal > div {
  z-index: 10;
  position: absolute !important;
  background: white;
}

body > div.ReactModalPortal > div > div {
  background: white;
  border-style: solid;
  border-radius: 2em;
  padding: 2em;
}

#buttons {
  display: inline-flex;
}

/*_____ fliper */

/* entire container, keeps perspective */

.flip-container {
  perspective: 1000px;
}

/* flip the pane when hovered */

.flipNow {
  transform: rotateY(180deg);
}

.flip-container,
.front,
.back {
  width: 100%;
  height: 100%;
}

/* hide back of pane during swap */

.front,
.back {
  backface-visibility: hidden;
  height: 0;

  form {
    background-color: rgba($white, 0.9);
    border-radius: $border-radius;
    padding: 5.5rem 6.875rem;
    max-width: 34.6rem;
    margin-bottom: 3.5rem;
    .main-logo {
      display: block;
      margin: 0 auto 2.5rem;
      width: 286px;
    }
    div {
      max-width: 20.875rem;
    }
  }
  button {
    width: 100%;
  }
  .form-control {
    margin-bottom: 1.25rem !important;
  }
}

/* front pane, placed above back */

.front {
  z-index: 2;
  /* for firefox 31 */
  transform: rotateY(0deg);
}

/* back, initially hidden pane */

.back {
  transform: rotateY(180deg);
  display: none;
  form {
    margin-top: -105px;
  }
}

.flipNow {
  .back {
    display: block;
  }
}

.typeTest {
  display: inline-flex;
  margin-bottom: 2em;
}

.radio {
  margin-right: 2em;
}

.radioB {
  margin-right: 0.5em;
}

.addFavorites {
  opacity: 0;
  transition: opacity 1s;
  position: inherit;
  font-size: 15px;
  margin-left: 2em;
  padding-bottom: 1em;
  padding-top: 1em;
  margin-top: -2.8em;
  width: 11.5em;
  margin-left: -1em;
  padding-left: 2em;
}

.addFavorites:hover {
  opacity: 1;
  color: black;
}

.favoriteScenario {
  justify-content: space-between;
  display: flex;
  margin-right: 10em;
  margin-left: 10em;
  margin-top: 3em;
}

.customIcon {
  border: solid 1px gray;
  border-radius: 25px 0px 0px 25px;
  width: 3em;
  height: 3.5em;
  position: fixed;
  right: 0;
  color: white;
  top: 30em;
  padding-top: 0.7em;
  padding-left: 1.5em;
  background-color: #e3e3fb;
  transition: width 1s, padding 1s;
  box-shadow: 0px 0px 2px #888;
}

.customIcon:hover {
  width: 11em;
  padding-right: 7em;
  color: black;
  cursor: pointer;
}

.scenariosList {
  position: absolute;
  margin-top: 4em;
  margin-left: 55em;
  width: 30em;
  overflow: scroll;
  overflow-x: hidden;
  height: 27em;
  line-height: 2em;
}

.pr-30em {
  padding-right: 30em;
}

.menu-icon::after {
  cursor: pointer;
  background-image: url(./assets/images/menu-icon.png);
  background-size: 100% 100%;
  display: inline-block;
  width: 2em;
  height: 2em;
  content: "";
}
.row {
  margin: 0;
}
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.space-between {
  justify-content: space-between;
}

.flex-container {
  flex-wrap: wrap;
}

@media screen and (max-width: 1400px) {
  .App-logo {
    height: 7em;
    width: 7em;
  }
  .App-title {
    font-size: 2em;
    margin-top: 1.5em;
  }
  .navbar-brand {
    font-size: 1em;
  }
}

.icon-explorer {
  width: 3em;
  margin-left: 0;
  margin-right: 0;
}

@media (max-width: 750px) {
  .modal-create {
    top: 30%;
    right: auto;
    bottom: "auto";
    margin-right: "-50%";
    transform: "translate(0%, 25%) !important";
    text-align: "center";
  }
  .pagination-between {
    justify-content: space-between !important;
  }
  .mt-explorer {
    margin-top: 1rem;
  }
  .margin-explorer {
    margin-bottom: 1rem;
  }
  .spacer {
    width: 100%;
    height: 8.5rem;
  }
}

@media (min-width: 751px) {
  .modal-create {
    top: 25%;
    left: 25%;
    right: auto;
    bottom: "auto";
    margin-right: "-50%";
    transform: "translate(-50%, -50%) !important";
    text-align: "center";
  }
  .spacer {
    width: 100%;
    height: 6.5rem;
  }
  .ml-30 {
    margin-left: 30px !important;
  }
}

@media (max-width: 751px) {
  .collapse.navbar-collapse,
  .collapse.show.navbar-collapse,
  .collapsing.navbar-collapse,
  .collapsing.show.navbar-collapse {
    position: fixed;
    z-index: 2000;
  }
}

.box-container {
  box-shadow: $standard-shadow;
  background-color: $white;
  border-radius: $border-radius;
  padding: 1.875rem;
  margin-bottom: 1.875rem;
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.25rem;
}

.header-actions {
  display: flex;
  align-items: center;
  button {
    margin-left: 1.25rem;
  }
}

.custom-back-button {
  background: rgb(168, 168, 168);
  border-color: rgb(168, 168, 168);

  &:disabled {
    background: rgb(214, 214, 214);
    border-color: rgb(214, 214, 214);
    cursor: auto;
  }
}

.recharts-legend-item-text {
  width: auto;
}

hr {
  margin-top: 5px;
  margin-bottom: 10px;
}

.page-title {
  font-size: $font-size-600;
  font-family: $font-family-bold;
  margin: 0;
  color: $secondary-color;

  span.fa {
    margin: 0 0 0 10px !important;
  }
}

.page-subtitle {
  font-size: $font-size-400;
  color: $black;
  margin: 0;
  font-family: $font-family-bold;
  margin-bottom: 1.25rem;
}

.title-form {
  color: $black;
  font-size: $font-size-200;
  margin-bottom: 0.625rem;
}

text {
  font-family: inherit !important;
}

.form-control,
input[type="text"] {
  background-color: $gray-1 !important;
  border: none !important;
  border-bottom: 1px solid $gray-3 !important;
  border-radius: 0 !important;
  color: $gray-5;
  font-size: 0.875rem;
  height: auto;
  min-height: 2.5rem;
  line-height: 0.938rem;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1.25rem;
  &:placeholder {
    font-weight: lighter;
    color: $gray-5;
  }
  &:focus {
    border: none;
    border-bottom: 1px solid $gray-3;
    background-color: $gray-1 !important;
    box-shadow: none !important;
  }
  &:disabled,
  &.disabled,
  &.disable,
  &[readonly] {
    border-bottom-color: $gray-3;
    color: $gray-3;
    background-color: $white;
    border-color: $gray-3;
  }
  &.form-control-output {
    background-color: transparent !important;
    color: $gray-6;
    text-transform: uppercase;
    padding: 0.5rem 0.616rem;
    line-height: 1.3rem;
  }
  &-light {
    background-color: $white !important;
    &:focus {
      background-color: $white !important;
    }
  }
  &-error {
    background-color: rgba($red-1, 0.05);
    border-bottom-color: $red-1;
  }
  &-success {
    background-color: rgba($green, 0.05);
    border-bottom-color: $green;
  }

  &:hover,
  &:active,
  &:visited,
  &:focus {
    outline: none;
    box-shadow: none;
    border: none;
  }
}

.form-control-light {
  background-color: $white !important;
  &:focus {
    background-color: $white !important;
  }
}

.ReactModal__Content {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  animation-name: bounceIn;
  animation-duration: 400ms;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.ReactModal__Content--after-open {
  opacity: 1;
}

.ReactModal__Content--before-close {
  opacity: 0;
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.7) translate3d(0, 0, 0);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.05);
  }
  80% {
    opacity: 1;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
}

.rw-dropdown-list .rw-widget-input {
  outline: none;
  background-color: $gray-1 !important;
  border: none !important;
  border-bottom: 1px solid $gray-3 !important;
  border-radius: 0 !important;
  color: $gray-5 !important;
  font-size: $font-size-050 !important;
  height: 2.5rem;

  &:focus,
  &:active {
    border-color: $primary-color;
    outline: none;
  }
}

.rw-dropdown-list.rw-state-focus {
  border-color: $primary-color !important;
  outline: none;
}

.rw-dropdown-list-input {
  font-size: 16px !important;
}

.rw-dropdown-list.rw-open .rw-widget-input {
  border-color: $primary-color;
  outline: none;

  &:focus,
  &:active,
  &:hover {
    border-color: $primary-color;
    outline: none;
  }
}

.rw-widget-input.rw-widget-picker.rw-widget-container {
  box-shadow: none !important;
}

.rw-list-option.rw-state-focus {
  border: none;
  background: $gray-1;
}

.rw-list-option.rw-state-selected {
  border-color: none;
  background: $primary-color;

  &:hover {
    background: $primary-color;
  }
}

.rw-state-focus > .rw-widget-container,
.rw-state-focus > .rw-widget-container:hover {
  border-color: $primary-color;
}

.rw-btn.rw-btn-select {
  &:focus,
  &:active {
    outline: none !important;
  }
}

.rdrDayToday .rdrDayNumber span:after {
  background: $primary-color;
}

.rdrMonthName {
  font-size: 20px;
  padding: 0 10px;
}

.rdrNextPrevButton {
  background: $primary-color;

  &:hover {
    background-color: darken($primary-color, 5%);
  }
}

.rdrPprevButton i {
  border-color: transparent #ffff transparent transparent;
}

.rdrNextButton i {
  border-color: transparent transparent transparent #ffff;
}

.main-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.main-header {
  background: $black;
  width: 100%;
  height: 4.375rem;
  padding-left: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    margin: 10px 20px;
    width: 220px;
    height: 100%;
  }
}

select {
  appearance: none;
  cursor: pointer;
  padding-right: 1.5rem !important;
  background: $white
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z' fill='%23CC1F20'/%3E%3C/svg%3E")
    no-repeat right 5px center / 18px !important;
  &:disabled {
    background: $gray-1
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z' /%3E%3C/svg%3E")
      no-repeat right 5px center / 18px !important;
  }
  &.is-invalid {
    background: $gray-1
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z' fill='red' /%3E%3C/svg%3E")
      no-repeat right 5px center / 18px !important;
  }
}

::-webkit-input-placeholder {
  text-transform: uppercase;
  color: $gray-9 !important;
}

.react-tabs {
  &__tab {
    margin-bottom: 0;
    font-size: $font-size-300 !important;
  }
}

.rdrDefinedRangesWrapper .rdrStaticRangeSelected {
  font-weight: normal;
  font-family: $font-family-bold;
}

.disabled-filter {
  &::before {
    content: none !important;
  }
  input {
    pointer-events: none;
    opacity: 0.6;
  }
}

.table-action-cell {
  padding: 0 !important;
  display: flex;
}

.table-action-button {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  span {
    margin: 0;
    color: $red;
    font-size: 1.125rem;
  }

  &:hover,
  &:active,
  &:visited,
  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    background: rgba($red, 0.12);
  }

  &:active {
    background: rgba($red, 0.18);
  }

  &:disabled {
    background: rgba($gray-2, 0.5);

    span {
      color: $gray-3;
    }
  }
}

strong {
  font-weight: normal;
  font-family: $font-family-bold;
}

.fa-ok,
.fa-ko {
  width: 0.75rem;
  height: 0.75rem;
  display: inline-block;
  border-radius: 50%;
  vertical-align: baseline;
}

.fa-ok {
  background: $green;
}

.fa-ko {
  background: $red;
  position: relative;
  &:before {
    content: "!";
    font-family: $font-family-bold;
    font-size: 10px;
    color: $white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.form-group {
  &-label {
    display: block;
    font-family: $font-family-bold;
    color: $black;
    font-size: $font-size-200;
    margin-bottom: 0.625rem;
  }
}

// datepicker color text in selected range

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: #666666 !important;
}

// datepicker
.rdrStartEdge,
.rdrInRange,
.rdrEndEdge {
  color: rgba(203, 30, 31, 0.17) !important;
  border-radius: 2px !important;
}
.rdrStartEdge {
  border-radius: 2px 0 0 2px !important;
}
.rdrInRange {
  border-radius: 0 !important;
}
.rdrEndEdge {
  border-radius: 0 2px 2px 0 !important;
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper)
  .rdrDayHovered
  .rdrDayNumber:after {
  border: 1px solid $primary-color;
  border-radius: 2px;
}

.rdrDayStartOfMonth .rdrDayInPreview,
.rdrDayStartOfMonth .rdrDayEndPreview,
.rdrDayStartOfWeek .rdrDayInPreview,
.rdrDayStartOfWeek .rdrDayEndPreview {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.rdrDayEndPreview {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.rdrDayEndOfMonth .rdrDayInPreview,
.rdrDayEndOfMonth .rdrDayStartPreview,
.rdrDayEndOfWeek .rdrDayInPreview,
.rdrDayEndOfWeek .rdrDayStartPreview {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.rdrDayStartPreview {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.rdrDayInPreview,
.rdrDayEndPreview,
.rdrDayStartPreview,
.rdrDayInPreview + .rdrDayNumber span,
.rdrDayEndPreview + .rdrDayNumber span,
.rdrDayStartPreview + .rdrDayNumber span {
  color: $primary-color !important;
}

.rdrMonthName {
  color: #010101;
}
.rdrWeekDay {
  color: #58595b;
}

.rw-dropdown-list {
  .rw-btn {
    color: $primary-color;
  }
}

.whiteDropdown {
  background-color: $white !important;
  .rw-widget-input.rw-widget-picker.rw-widget-container {
    background-color: $white !important;
  }
}

.recharts-responsive-container .recharts-legend-wrapper {
  bottom: 0px !important;
  ul > li.recharts-legend-item {
    margin-right: 20px !important;
  }
  .recharts-text.recharts-label {
    transform: translateY(20px);
  }
}
