@import "../../../../../variables.scss";

.ScenarioActions {
  width: 40%;
  display: flex;
  justify-content: space-evenly;
  min-width: 215px;

  span {
    margin-right: 5px;
  }
}

.Content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 1.25rem 0 0;
}

.Header {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.25rem;
  padding-left: 0;
  margin-right: 1.25rem;
  border-right: 1px solid $gray-2;
}

.Name {
  margin-bottom: 0;
  font-size: $font-size-200;
  color: $black;
  font-family: $font-family-bold;
  display: block;
  max-width: 12rem;
  margin-right: 5px;
  white-space: initial;
}

.CreatedAt {
  font-size: $font-size-100;
  color: $gray-11;
}

.Wrapper {
  display: flex;
  margin: 0 !important;
  padding: 0 1.25rem !important;
  border-right: 1px solid $gray-2;
}

.FilterInput {
  width: 14.75rem !important;
  background-color: $gray-1 !important;
  border: none !important;
  border-bottom: 1px solid $gray-3 !important;
  border-radius: 0 !important;
  color: $gray-5 !important;
  font-size: $font-size-050 !important;
  min-height: 2.5rem;
}

.NameDescContainer {
  display: flex;
}
