.CardsWrapper {
  display: grid;
  column-gap: 1.875rem;
  grid-template-columns: repeat(3, 1fr);
}
.title-form {
  font-size: 1.125rem;
  color: #010101;
  font-weight: 600;
}

.VirtualUsersDropdown {
  width: 200px;
  .rw-widget-input {
    background-color: white !important;
  }
}
