@import '../../../../../../variables.scss';

.Container {
  background: $white;
  border: 1px solid $gray-2;
  padding: 5px;
  padding-top: 10px;
  border-radius: 4px;
  box-shadow: $standard-shadow;
  text-align: center;
}

.ContentContainer {
  padding: 0px 5px;
  padding-top: 5px;
}

.Title {
  line-height: 12px;
  font-size: $font-size-200;  
}

.Value {
  font-size: $font-size-400;
  font-family: $font-family-bold;
}
