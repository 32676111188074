@import "../../../../variables.scss";

.Container {
  position: relative;
}

.HeaderContainer {
  background: #fff;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid $gray-3;
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
  color: $primary-color;
  cursor: pointer;

  &:hover {
    .DateButton {
      transition: all 0.2s ease-in-out;
      background: #f5f5f5;
    }
  }

  span {
    width: unset;
  }
}

.DateButton {
  padding: 0 0.75rem;
  height: 100%;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;

  i {
    margin: 0;
  }
}

.DateRange {
  padding: 0 1.25rem;
  font-size: $font-size-100;
  color: $gray-9;
  font-family: $font-family-bold;
  line-height: 0;
  margin-top: 2px;
}

.RightIcon {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.DateRangePickerContainer {
  position: absolute;
  background: $white;
  box-shadow: $standard-shadow;
  border-radius: $border-radius;
  top: 45px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-5px);
  z-index: 100000;
  right: 0;
}

.DatePickerOpened {
  transition: all 0.2s ease-in-out;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
