.ToolContainer {
  padding: 15px;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  margin-bottom: 15px;
}

.ToolHeaderContainer {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 15px;

  h5 {
    margin: 0;
  }
}

.ToolTitle {
  width: 40%;
  display: inline-block;
}

.ToolSelection {
  width: 60%;
  display: flex;
  justify-content: center;
}

.ToolDropdown {
  width: 80%;
}

.ParseFilesContainer {
  display: flex;
  height: 40px;
  margin-bottom: 15px;
}

.ParseFilesButton {
  width: 50%;
}

.ParseFilesBundle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  p {
    margin: 0;
  }
}

.BundleIcon {
  font-size: 30px;
  margin-left: 0 !important;
  color: #259e00;
}

.ParsingMessage {
  font-size: 15px;
  margin-left: 5px;
  width: auto;
}

.ErrorMessage {
  font-size: 15px;
  width: auto;
  font-weight: bold;
  color: #eb0c0c;
}

.BundleMessage {
  font-size: 15px;
  margin-left: 5px;
  width: auto;
  color: #259e00;
}

.DownloadIcon {
  margin: 0 !important;
  margin-left: 5px !important;
  font-size: 15px;
  cursor: pointer;
  color: rgb(102, 102, 102);
  transition: all 0.1s ease-in-out;

  &:hover {
    color: rgb(73, 73, 73);
  }

  &:active {
    color: rgb(102, 102, 102);
    transform: scale(1);
  }
}

.Bundle {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
