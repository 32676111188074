@import "../../../../../variables.scss";

.ModalTitle {
  text-align: center;

  span {
    font-weight: bold;
    color: $primary-color;
  }
}

.ClientSelection {
  width: 250px;
  margin-right: 7px;
}

.Content {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
}

.ShareButton {
  min-width: unset !important;
}

.CreateClientButton {
  min-width: unset !important;
}
