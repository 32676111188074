.InputRow {
  display: flex;
  width: 100%;
  padding-bottom: 15px;
  padding-top: 15px;
}

.InputContainer {
  padding-right: 5px;
  padding-left: 5px;
  position: relative;
}

.Input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #9e9e9e;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #9e9e9e;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #9e9e9e;
}
.LargeInput {
  width: 75%;
}

.MediumInput {
  width: 50%;
}

.SmallInput {
  width: 25%;
}

.InputLabel {
  position: absolute;
  top: -20px;
  left: 8px;
  margin: 0;
  font-size: 15px;
  width: 100%;
  text-align: left;
  color: #9e9e9e;
}

.AdditionalInfoContainer {
  display: flex;
}

.DynamicFieldsContainer {
  width: 50%;
  border: 1px solid #ced4da;
  padding: 5px;
  padding-top: 5px;
  border-radius: 5px;
  margin-right: 5px;
  margin-left: 5px;
  height: 275px;
}

.DynamicFieldsWrapper {
  overflow: auto;
  height: calc(100% - 27px);
  padding: 5px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #dedede;
    border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #c9c9c9;
  }
}

.DynamicFieldsTitle {
  text-align: left;
  color: #9e9e9e;
}

.DynamicField {
  input {
    font-size: 12px;
    margin-bottom: 5px;
  }
}

.DynamicFieldSeparator {
  width: 100%;
  border-top: 1px solid #e4e4e4;
  margin-top: 10px;
  margin-bottom: 10px;
}

.AddField {
  font-size: 30px;
  color: #dedede;
  cursor: pointer;
  &:hover {
    color: #c9c9c9;
  }
  &:active {
    color: #d1d1d1;
  }
}

.Footer {
  margin: 0 5px;
  width: 100%;
  display: flex;
}
