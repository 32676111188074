@import "../../../../../variables.scss";

.FieldName {
  font-size: 10px;
  color: #8d8d8d;
  line-height: 15px;
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Field{
  width: 10rem;
}

.Label {  
  font-size: $font-size-200;  
  font-family: $font-family-bold;  
}

.LabelContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;  
  border-bottom: 1px solid $gray-2;
  padding-bottom: 0.469rem;
}

.ValueContainer {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.438rem;
  font-size: $font-size-200;
  color: $gray-6;
  border-bottom: 1px solid $gray-2;
  border-right: 1px solid $gray-2;
}
