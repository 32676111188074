@import "../../../../../variables.scss";

.Label {
  margin-right: 3px;
  font-size: $font-size-400;
  color: $black;
  font-family: $font-family-bold;
  
}

.Field {
  display: flex;
  justify-content: flex-start;
  margin-right: 3rem;
  margin-bottom: 1rem;
}

.ValueContainer {
  font-size:$font-size-300;
  color: $gray-11;  
}

.IsUndefined {
  background: #7c7c7c;
}
