$medium-max-width: 980px;
$medium-min-width: 720px;

$primary-color: #CC1F20;
$secondary-color: #252631;
$green: #32a852;
$blue: #38bde8;
$gold: #ecb102;
$red: #bf1b1b;

%button-link {
  background: none;
  border: none;
  color: $primary-color;
  text-decoration: none;
  padding: 0;
  &:hover {
    text-decoration: underline;
  }
}

@mixin small {
  @media (max-width: $medium-min-width) {
    @content;
  }
}

@mixin medium {
  @media (min-width: $medium-min-width) and (max-width: $medium-max-width) {
    @content;
  }
}


// Perficient colors
$red:       #CC1F20;
$red-1:     #E61717;
$red-2:     #8D0E11;
$gold:      #B79967;
$gold-1:    #C9B38D;
$gold-2:    #8C734B;
$white:     #ffffff;
$gray-1:    #f8f8f8;
$gray-2:    #E8E8E8;
$gray-3:    #D1D3D4;
$gray-4:    #9E9E9E;
$gray-5:    #58595B;
$gray-6:    #444444;
$gray-7:    #222222;
$gray-8:    #fbfbfb;
$gray-9:    #7C7C7C;
$gray-10:   #E7E7E7;
$gray-11:   #4E4A56;
$black:     #010101;

$green:     #00BD51;
$yellow:    #FEC256;
$blue:      #009CEE;

$border-radius: 0.125rem;

//Font-size
$font-size-050:   0.75rem;//12px
$font-size-100:   0.813rem;//13px;
$font-size-200:   0.875rem;//14px;
$font-size-300:   1rem;//16px;
$font-size-400:   1.125rem;//18px;
$font-size-500:   1.25rem;//20px;
$font-size-600:   1.375rem;//22px;
$font-size-700:   1.5rem;//24px;

//Shadow
$standard-shadow:   0 2px 4px 0 rgba(50,43,43,0.14);
$hover-shadow:      1px 4px 10px 2px rgba(68,57,58,0.15);

//Font-family
$font-family-base: 'lato-regular', sans-serif;
$font-family-thin: 'lato-thin', sans-serif;
$font-family-light: 'lato-light', sans-serif;
$font-family-bold: 'lato-bold', sans-serif;
$font-family-black: 'lato-black', sans-serif;