@import "../../../../variables.scss";
@import "../../../../index.scss";

.react-tabs {
  -webkit-tap-highlight-color: transparent;

  &__tab-list {
    margin-bottom: 1.25rem;
    padding: 0;
    position: relative;
    width: 100%;
    border-bottom: 1px solid $gray-10;
  }

  &__tab {
    display: inline-block;
    padding: 0 1.625rem 1rem;
    border-bottom: 3px solid transparent;
    font-size: $font-size-200;
    cursor: pointer;

    &:hover {
      color: $gold;
    }

    &--selected {
      z-index: 3;
      cursor: default;
      border-bottom: 3px solid $gold;
      font-family: $font-family-bold;
      color: $black;
      &:hover {
        color: $black;
      }
    }

    &--disabled {
      color: GrayText;
      cursor: default;
    }

    &:focus {
      outline: none;
    }
  }

  &__tab-panel {
    display: none;
    @extend .box-container;
    border-radius: 0 4px 4px 4px;
    position: relative;
    z-index: 2;

    &--selected {
      display: block;
    }
  }
}
