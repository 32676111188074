@import "../../../../../variables.scss";

.ScenarioActions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.25rem;
  padding: 0 1.25rem;
  button {
    margin: 0 0.313rem;
    min-width: 7.5rem;
    max-width: 7.5rem;
    padding: 0 1.25rem;
  }
}

.FieldsContainer {
  position: absolute;
  left: 0;
  top: calc(100%);
  z-index: 10;
  background: $white;
  box-shadow: $standard-shadow;
  border-radius: $border-radius;
  padding: 0 0 1.25rem 0;
  min-width: 100%;
  margin-top: -1rem;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-5px);
}

.Bg {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba($black, 0.1);
  left: 0;
  top: 0;
  z-index: 10;
  display: none;
}

.Columns {
  color: $gray-5;
  font-size: $font-size-200;
  position: relative;
  z-index: 20;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
  transition: opacity linear 0.3s;

  > img {
    margin-left: 0;
    margin-right: 0.563rem;
    width: 1.25rem;
  }
}

.FieldsContainerOpen {
  transition: all 0.2s ease-in-out;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.BgVisible {
  display: block;
}

.formGroup {
  width: 19rem;
}

.DropdownLikeContainer {
  position: relative;
  display: inline-block;
  width: 100%;
}

.FieldsWrapper {
  max-height: 306px;
  overflow-y: auto;
}

.Controls {
  margin-top: 21px;
  position: relative;
  top: 41px;
}

.RefreshButton {
  min-width: 0px !important;
  padding: 10px !important;
  margin-left: 10px;

  span {
    margin: 0 !important;
  }
}

.ClearFilters {
  margin-left: 10px;
}

.DropdownLikeWrapper {
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
}

.DropdownLike {
  background: $gray-1;
  padding: 0 25px 0 3px;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid $gray-3;
  height: 2.5rem;
  cursor: pointer;
  margin-bottom: 1.25rem;
  display: flex;
  align-items: center;
}

.FieldLabel {
  background: $gray-2;
  margin: 0 3px;
  padding: 0 5px;
  border-radius: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: 33px;
}

.ArrowIcon {
  margin: 0 !important;
  margin-left: 0 !important;
  font-size: 1.4rem;
  color: $red;
  position: absolute;
  right: 4px;
  &[class*="angle"]::before {
    margin-left: unset;
  }
}
