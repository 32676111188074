.Container {
  width: 45px;
  height: 64px;
  border-radius: 3px;
  font-family: Arial, Tahoma, sans-serif;
  font-weight: 300;
  background: #848484;
  position: relative;
  border-radius: 2px;
  text-align: left;
  font-size: 9px;
  color: #e8e8e8;
  padding: 4px;
  padding-top: 12px;
  word-wrap: break-word;
  text-align: center;
  margin-right: 4px;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  flex-shrink: 0;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-bottom-left-radius: 2px;
    border-width: 5px;
    border-style: solid;
    border-color: #fff #fff rgba(255, 255, 255, 0.35) rgba(255, 255, 255, 0.35);
  }

  span.FilePath {
    width: 100%;
    overflow: hidden;
    height: 100%;
  }

  span.RemoveFileButton {
    position: absolute;
    top: 0px;
    left: 2px;
    cursor: pointer;
    color: white;
  }
}
