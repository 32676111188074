@import "../../../variables.scss";

.MainContent {
  width: 100vw;
  height: 100vh;
  background: $secondary-color;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.Logo {
  width: 100px;
  height: 100px;
}
.Title {
  font-size: 3rem;
}

.TitleContainer {
  text-align: center;
}

.MessageContainer {
  text-align: center;
  font-size: 25px;
}

.ButtonContainer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.Icon {
  margin: 0;
  margin-right: 10px;
}
