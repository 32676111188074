@import "../../../../../variables.scss";

.ModalTitle {
  text-align: center;
  span {
    font-weight: bold;
    color: $primary-color;
  }
}

.CreateButton {
  width: 100%;
}

.TextArea {
  min-height: 64px;
}
