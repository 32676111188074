@import "../../../../variables.scss";

.Container {
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
  flex-direction: column;
  border-radius: 5px;
  color: #494949;

  span {
    font-size: 16px;
    margin-left: 2px;
  }
}

.Selector {
  margin-bottom: 1rem;
  padding: 0;
  position: relative;
  width: 100%;
  border-bottom: 1px solid $gray-10;
}

.Option {
  display: inline-block;
  padding: 0 1.625rem 0.625rem;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  margin-bottom: 0;
  font-size: $font-size-300 !important;

  &:hover {
    color: $gold;
  }
}

.Option_selected {
  z-index: 3;
  cursor: default;
  border-bottom: 3px solid $gold;
  font-family: $font-family-bold;
  color: $black;
  &:hover {
    color: $black;
  }
}
