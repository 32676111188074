@import '../../../../../variables.scss';

.Field {
 width: 6.25rem;
}

.LabelContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;  
  border-bottom: 1px solid $gray-2;
  padding-bottom: 0.469rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.Label {  
  font-size: $font-size-200;  
  font-family: $font-family-bold;  
}

.ValueContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  height: 3.438rem;
  font-size: $font-size-200;
  color: $gray-6;
  border-bottom: 1px solid $gray-2;
  border-left: 1px solid $gray-2;
  width: 6rem;
  padding: 0 0.625rem;
}

.BaselineLabel {
  font-size: 10px;
  color: $gray-9;  
  position: relative;
}
