@import "../../../../variables.scss";

.Header {
  background-color: $gray-8;
  width: 20%;
  color: $black;
  position: relative;
  z-index: 10;
  height: calc(100vh - 4.375rem);
  border-right: 1px solid $gray-2;  

  @include medium {
    width: 65px;
  }

  @include small {
    width: 100%;
    height: 50px;
  }
}
