@import "../../../../../variables.scss";

.ScalabilityModelWrapper {
  height: 500px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1.875rem;
  -webkit-column-gap: 1.875rem;
  column-gap: 1.875rem;
}
.boxWrapper {
  height: 100%;
  width: 100%;
}

.boxTitle {
  margin: 0.5rem 0 1.25rem;
  font-size: 1.125rem;
  font-weight: bold;
}

.box {
  box-shadow: $standard-shadow;
  background-color: $white;
  border-radius: $border-radius;
  padding: 1.875rem;
  margin-bottom: 2.5rem;
  width: 100%;
  height: 450px;
}

.PlaceholderWrapper {
  height: 500px;
}
