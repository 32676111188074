@import "../../../variables.scss";

$cancel-color: #979797;

.swal2-popup {
  font-family: inherit;

  .swal2-header {
    overflow: overlay;
  }

  .swal2-title {
    display: flex;
    justify-content: center;
  }

  .swal2-input {
    border: 2px solid #d6d6d6 !important;
    background-color: #fff !important;

    &:focus {
      color: $secondary-color !important;
      border: 2px solid $primary-color !important;
    }

    &::placeholder {
      color: #a0a0a0;
    }
  }

  .swal2-actions {
    button {
      box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3),
        0 1px 5px -2px rgba(152, 162, 179, 0.3) !important;
    }

    button.swal2-confirm {
      background-color: $primary-color !important;

      &:hover {
        color: #fff;
        background-color: darken($primary-color, 2%) !important;
      }

      &:active {
        color: #fff;
        background-color: darken($primary-color, 5%) !important;
      }

      &:focus {
        outline: 0;
      }

      &:disabled {
        background-color: lighten($primary-color, 20%) !important;
        cursor: auto;
      }
    }

    button.swal2-cancel {
      background-color: $cancel-color !important;

      &:focus {
        outline: 0;
      }

      &:disabled {
        background-color: lighten($cancel-color, 20%) !important;
        cursor: auto;
      }
    }
  }

  .swal2-title-entity {
    color: $primary-color;
    font-weight: bold;
  }

  .swal2-content-default {
    font-weight: bold;
  }

  .swal2-content-info {
    background: transparentize($primary-color, 0.7);
    padding: 5px;
    border-radius: 5px;
    margin-top: 10px;
  }
}

.swal2-popup.delete-warning {
  .swal2-content-info {
    background: #ffe0c4b7;
  }
}

.swal2-popup.danger {
  border: 3px solid $red;

  .danger-icon {
    color: $red;
    border-color: $red;
  }

  .danger-footer {
    color: $red;
    font-weight: bold;
    font-size: 25px;
    padding-top: 20px;
  }

  .swal2-actions {
    button {
      box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3),
        0 1px 5px -2px rgba(152, 162, 179, 0.3) !important;
    }

    button.swal2-confirm {
      background-color: $red !important;
      font-weight: bold;

      &:hover {
        color: #fff;
        background-color: darken($red, 2%) !important;
      }
    }
  }

  .swal2-content-info {
    background: #ffcecc;
  }
}
