@import "../../../../variables.scss";

.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.625rem;
}

.ButtonLink {
  @extend %button-link;
}

.TabList {
  margin-bottom: 1vh;
}

.CreateTest {
  margin-right: 20px;
  height: 40px;

  span {
    margin-right: 0.5rem;
  }

  &:hover {
    color: #eee;
  }
}

.CompareButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    width: auto;
    font-size: 10px;
  }
}

.CompareButtonIcon {
  position: relative;
  font-size: 15px !important;
  margin-left: 0 !important;
}

.BoxContainer {
  margin-top: 10px;
}

.BoxContainerClosed {
  max-height: 0;
  overflow: hidden;
  display: none;
}

.CompareCheckBoxContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.compareButton {
  position: relative;
  left: 0;
  top: -35px;
  z-index: 10;
}

.CompareCheckBoxDisabled {
  cursor: default;
  & > div {
    border: 2px solid rgb(209, 209, 209);
  }

  &:hover > div {
    border: 2px solid rgb(209, 209, 209);
  }
}

.Actions {
  display: flex;
  align-items: center;
}

.UploadTestButton {
  margin: 0;
  margin-left: 1.25rem;
  display: flex;
  align-items: center;
}

.LastDateCell {
  line-height: 12px;

  .LastDateDate {
    font-size: $font-size-200;
  }

  .LastDateHour {
    height: 12px;
    padding-top: 1px;
    font-size: 0.625rem;
  }
}
