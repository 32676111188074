.ValueContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin: 0;
  }
}

.Arrow {
  margin-right: 10px;
}
