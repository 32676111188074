.Header {
  margin-bottom: 2.5rem;
}

.TableWrapper {
  padding-top: 1.25rem !important;
}

.SelectorContainer > div {
  margin-top: 0;
}

.EndpointInfoWrapper {
  display: flex;
  margin-bottom: 0.625rem;
}

.PieChart {
  width: 400px;
  height: 300px;
}

.EndpointInfo {
  flex-grow: 1;
  padding: 0;
}

.ScalabilityModelWrapper {
  height: 500px;
}

.ResponseCodeDetailedContainer {
  min-height: 300px;
}

.virtualDropdown {
  width: 200px;

  .rw-widget-input {
    background-color: white !important;
  }

  .rw-input .rw-dropdown-list-input {
    font-size: 20px !important;
  }
}
