@import "../../../../../../variables.scss";

.LabelContainer {
  padding: 5px;
  height: 40px;
  white-space: nowrap;

  h3 {
    margin: 0;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 28px;
    font-size: 25px;
    font-weight: bold;
  }
}

.InfoContainer {
  display: flex;
  flex-wrap: wrap;
}

.ResponseTimeMetrics {
  display: grid;
  column-gap: 1.875rem;
  grid-template-columns: repeat(auto-fill, minmax(18%, 1fr));
}

.RequestsContainer {
  display: flex;
  justify-content: space-around;
  flex-grow: 1;
}

.Separator {
  height: 60px;
  margin: 0 30px;
  border-right: 1px solid #0003;
}

.KO,
.OK {
  min-width: 40px;
  display: flex;
  white-space: nowrap;
}

.Percentage {
  min-width: 40px;
  display: flex;
  white-space: nowrap;
}

.OK {
  color: $green;
}

.KO {
  color: $red;
}

.Metric,
.RequestsBox,
.LargeMetric {
  display: flex;
  align-items: center;
  justify-content: center;
}

.LargeMetric {
  margin-right: 1.875rem;
}

.MetricLabel,
.RequestsTitle,
.LargeMetricLabel {
  color: $gray-6;
  font-size: $font-size-300;
  margin-left: 1rem;
}

.MetricValue,
.RequestsCount,
.LargeMetricValue {
  color: $black;
  font-size: 1.563rem;
  font-family: $font-family-bold;
}
