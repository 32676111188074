@import "../../../../variables.scss";

.Icon {
  margin: 0 !important;
}

.Tooltip {
  border-radius: 4px;
  box-shadow: 0 2px 2px -1px rgba(42, 43, 44, 0.3),
    0 1px 5px -2px rgba(42, 43, 44, 0.3);
  opacity: 1 !important;
  padding: 3px 6px;
}

.TooltipParagraph {
  margin: 0;
  text-align: center;
  font-weight: bold;
}
