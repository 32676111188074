@import "../../../../../globals.module.scss";
@import "../../../../../variables.scss";

.headerLeft {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .signOut {
    background: $gray-7;
    width: 4.375rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1.625rem;
    a {
      color: $white !important;
      font-size: $font-size-700 !important;
      transform: translateX(50%);
    }
  }
}

.photo {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: $gray-1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $black;
  margin-right: 1rem;
}

.Button {
  background: none;
  border: none;
  color: $white;
  text-align: start;
  display: flex;
  align-items: center;
  justify-content: space-between;

  [class~="fa"] {
    margin-left: 0;
  }
  &:focus {
    outline: none;
  }

  @include medium {
    padding: 0.3rem;
  }
}

.UserNameContainer {
  display: flex;
  align-items: center;
}

.arrowIcon {
  float: right;
  margin: 0 !important;
  font-size: 1.5rem;
  color: $red;

  &[class*="angle-down"]::before {
    margin-left: unset;
  }
  @include medium {
    &[class*="angle-down"] {
      transform: rotate(270deg);
    }

    &[class*="angle-up"] {
      transform: rotate(270deg);
    }
  }
}

.user {
  min-width: 8.125rem;
  padding-right: 0.5rem;
  span {
    font-family: $font-family-bold;
    font-size: $font-size-200;
    display: block;
    line-height: 1rem;
  }
}

.NavItem {
  width: auto;
  font-family: $font-family-base !important;
  margin-top: 3px;
  @include medium {
    display: none;
  }
}

.MenuContainer {
  position: relative;
}

.List {
  background-color: $gray-7;
  padding: 0;
  transform: scaleY(0);
  transform-origin: top;
  overflow: hidden;
  transition: transform 0.26s ease;
  position: absolute;
  right: 0;
  top: calc(100% + 0.8rem);
  width: 100%;
  box-shadow: $standard-shadow;
  z-index: 10;

  @include medium {
    display: none;
  }
  a {
    color: $gray-3;
    &:hover {
      color: $white;
      background: $gold;
    }
  }
}

.ListOpen {
  transform: scaleY(1);
  @include medium {
    display: block;
    padding: 0;
    position: absolute;
    width: 180px;
    left: 70px;
    transform: translateY(-34px);
    background-color: $gray-7;
    border-radius: 0px 10px 10px 10px;
    box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3),
      0 1px 5px -2px rgba(152, 162, 179, 0.3);
  }
}

.UserIcon {
  margin: 0;
  font-size: 1.5rem;
}

.UserMenu {
  white-space: nowrap;
  overflow: hidden;
}

.Version {
  color: $gray-5;
  text-align: center;
}
