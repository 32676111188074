@font-face {
  font-family: 'lato-light';
  src: local('lato-light'), url('../../../assets/fonts/Lato-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'lato-regular';
  src: local('lato-regular'), url('../../../assets/fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'lato-bold';
  src: local('lato-bold'), url('../../../assets/fonts/Lato-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'lato-black';
  src: local('lato-black'), url('../../../assets/fonts/Lato-Black.ttf') format('truetype');
}