@import "../../../../../variables.scss";

.ThreadsSelectorContainer {
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;
  border-radius: 5px;
  margin-top: 10px;
  color: #807e7e;

  span {
    font-size: 16px;
    margin-left: 2px;
  }
}

.ThreadsSelector {
  border-radius: 5px;
  display: flex;
}

.ThreadsGroup {
  border: 1px solid rgba($color: #000000, $alpha: 0.2);
  font-size: 16px;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 2px;
  margin-left: 2px;
  flex-grow: 1;
  text-align: center;
  cursor: pointer;
}

.ThreadsGroup_selected {
  background: #17a2b8;
  color: #fff;
  border: 1px solid #17a2b8;
  cursor: default;
}

.VirtualUsersDropdown {
  width: 200px;
  margin-bottom: 1rem;

  .rw-widget-input {
    background-color: white !important;
  }

  .rw-dropdown-list-input {
    font-size: 20px !important;
  }
}
