@import "../../../globals.module.scss";
@import "../../../variables.scss";

.Header {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  overflow: hidden;

  .RoleLabel {
    margin-left: 0;
  }

  .Icon {
    font-size: 30px;
    margin-right: 10px;
    margin-bottom: 6px;
  }
}

.Body {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.MainContent {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.UserInfoForm {
  width: 100%;
  min-width: 300px;
  padding: 0 1.875rem 0 0;
}

.Input {
  margin-bottom: 20px;

  label {
    margin: 0 0 8px;
  }
}

.ChangePasswordContainer {
  width: 100%;
  min-width: 200px;
  display: flex;
  justify-content: center;
  padding: 0 0 0 1.875rem;
  padding-top: 0;
  form {
    width: 100%;
  }
}

.UserProfileContainer {
  max-width: 860px;
  width: 30%;
  padding: 2em;
  margin: 6rem 2rem;
}

.TogglePassword {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 61%;
  right: 0;
  cursor: pointer;
  transform: translate(0, -50%);
  padding: 10px;
  color: gray;
  z-index: 5;
  &:hover:enabled {
    color: $primary-color;
  }
  &:focus {
    outline: none;
  }
}

.InputGroup {
  position: relative;
  width: 100%;
}

.TitleForm {
  margin-bottom: 33px;
  text-align: center;
  margin-top: 1.5rem;
}

.Role {
  font-size: 15px;
  text-align: center;
  margin-top: 7px;
}

.InputLabel {
  margin-bottom: 8px;
  width: 100%;
  text-align: left;
}

.ErrorMessage {
  color: $red;
  margin-bottom: 10px;
}

.changePasswordButton {
  margin-top: 8px;
}

.updateButton {
  margin-top: 99px;
}
