.Header {
  display: flex;
  margin: 1.75rem 0 1.188rem;
  justify-content: space-between;
  align-items: center;
}

.ButtonContainer {
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.ScenarioMainContainer {
  margin-bottom: 10px;
  display: flex;
}

.ScenarioContainer {
  flex-grow: 1;
}

.DateRangePickerContainer {
  display: flex;
  margin-bottom: 10px;
}

.Content {
  display: flex;  
  align-items: flex-end;  
}

.ChartContainer {
  margin-bottom: 3.125rem;
}

.BackButton {
  margin-bottom: 10px;
}
