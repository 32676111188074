@import "../../../../variables.scss";

.Wrapper {
  min-width: 100%;
  overflow: auto;
  margin-bottom: 1.25rem;

  &::-webkit-scrollbar {
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    height: 6px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.15);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.25);
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

.Breadcrumbs {
  display: flex;
  align-items: center;
  min-height: 24px;
}

.BreadcrumbTitle {
  color: $gray-5;
  font-size: $font-size-050;
  margin-right: 0.5rem;
  line-height: 12px;
}

.BreadcrumbContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:hover {
    .BreadcrumbTitle {
      color: $gold;
    }

    .Content {
      color: $gold;
      text-decoration: none;
    }
  }
}

.CurrentBreadcrumbContainer {
  cursor: default;
  pointer-events: none;

  .BreadcrumbTitle {
    color: $gray-5;
    font-family: $font-family-bold;
  }

  .Content {
    color: $black;
  }

  &:hover {
    cursor: default;
    .Content {
      color: $black;
    }

    .BreadcrumbTitle {
      color: $gray-5;
    }
  }
}

.Breadcrumb {
  display: inline-block;
  width: 100%;
  margin-right: 0.5rem;
}

.Content {
  font-weight: bold;
  line-height: 12px;
  color: $gray-5;
  font-size: $font-size-050;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

.Separator {
  padding: 0 0.5rem;
  font-size: $font-size-050;
  color: $gray-5;
}
