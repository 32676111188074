@import "../components/Button/Button.module.scss";

.ReactTable {
  border: none;

  .rt-noData {
    top: auto;
    bottom: 1.875rem;
    text-align: center;
    width: 99.9%;
    background: $white;
  }

  .rt-thead {
    &.-header {
      box-shadow: none;
    }
    .rt-tr {
      border-left: 1px solid $white;
      border-right: 1px solid $white;
    }
    .rt-th {
      border-right: none;
      font-size: $font-size-200;
      font-family: $font-family-bold;
      color: $black;
      padding: 0.625rem 1rem 0.625rem 0;
      display: flex;
      justify-content: center;

      &.-sort-desc {
        box-shadow: none;
        position: relative;
        &::after {
          content: "\f0dd";
          font: normal normal normal 14px/1 FontAwesome;
          color: $red;
          position: relative;
          right: -0.5rem;
          top: 2px;
          font-size: $font-size-200;
        }
      }
      &.-sort-asc {
        box-shadow: none;
        position: relative;
        &::after {
          content: "\f0de";
          font: normal normal normal 14px/1 FontAwesome;
          color: $red;
          position: relative;
          right: -0.5rem;
          top: 2px;
          font-size: $font-size-200;
        }
      }

      &.-cursor-pointer:not(.-sort-asc):not(.-sort-desc) {
        box-shadow: none;
        position: relative;
        &::after {
          content: "\f0dc";
          font: normal normal normal 14px/1 FontAwesome;
          color: $gray-4;
          position: relative;
          right: -0.5rem;
          top: 2px;
          font-size: $font-size-200;
        }
      }
    }
    &.-filters {
      .rt-tr {
        border-left: 1px solid $white;
        border-right: 1px solid $white;
      }
      .rt-th {
        border-right: none;
        padding-top: 0;
        &::before {
          content: "\f002";
          font: normal normal normal 14px/1 FontAwesome;
          position: absolute;
          bottom: 1.438rem;
          left: 9px;
          z-index: 10;
          color: $gray-4;
          top: 13px;
        }
        input {
          padding-left: 1.875rem;
        }
      }
    }
    .rt-resizable-header-content {
      display: flex;
      align-items: center;
    }
    &.-headerGroups {
      background: none;
      border-bottom: none;
    }
  }

  .-header {
    .rt-th {
      padding-bottom: 0.5rem;
    }
  }

  .rt-tbody {
    margin-bottom: 1rem;
    border: 1px solid $gray-2;
    border-top: 0;
    .rt-td {
      border-right: none;
      color: $gray-6;
      font-size: $font-size-200;
      padding: 0.655rem 1rem;

      a {
        color: $gray-6;
      }
    }
  }
}

.pagination-bottom {
  .-pagination {
    position: relative;
    box-shadow: none;
    border: none;
    justify-content: flex-end;
    font-size: $font-size-200;
    padding: 0;

    .-btn,
    .-btn:not([disabled]) {
      @extend %SecondaryButton;
      font-family: $font-family-bold;
      flex: initial;
      width: 5rem;
      border: 1px solid $red;
      &::before {
        position: absolute;
        width: 100%;
        color: $red;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: $font-size-500;
        background: $white;
        z-index: 10;
      }
    }

    .-center {
      flex: initial;
      margin: 0 1rem;
      .-pageSizeOptions {
        position: absolute !important;
        right: 22.25rem;
        top: 1px;
        margin: 0;
      }
    }

    .-previous,
    .-next {
      flex: initial;
    }

    .-pageInfo {
      margin: 0;
      color: $gray-9;
    }

    .-pageJump {
      margin: 0 0.5rem;
    }

    select {
      color: $gray-9;
    }
  }
}

.ReactTable.EndpointsStatisticsTable {
  .rt-table {
    margin-bottom: 1.875rem;
  }

  .-headerGroups {
    .rt-th {
      border-right: 1px dashed $gray-6;
      padding-top: 5px;
      &:last-child {
        border-right: none;
      }
    }
  }

  .-header {
    .rt-th {
      justify-content: flex-start;
      &:first-child {
        padding-left: 0 !important;
      }
    }
  }

  .rt-tfoot {
    border-left: 1px solid $white;
    border-right: 1px solid $white;
    box-shadow: none;
    .rt-tr {
      padding-top: 0.656rem;
    }
    .rt-td {
      border: none;
      background: $gray-1;
      color: $gray-6;
      font-size: $font-size-200;
      font-weight: normal;
      font-family: $font-family-bold;
      &:first-child {
        text-align: right !important;
        padding-right: 1.094rem;
      }
    }
  }

  .rt-th,
  .rt-td {
    padding-left: 1.25rem;
    &:first-child,
    &:nth-child(4),
    &:nth-child(10) {
      border-right: 1px dashed $gray-6;
    }
  }

  .-filters {
    .rt-th {
      padding-left: 1.25rem;
      padding-right: 0.5rem;
      &:before {
        left: 1.8rem;
      }
      &:last-child {
        &:before {
          content: none;
        }
      }
      &:first-child {
        padding-left: 0 !important;
        &:before {
          left: 9px;
        }
      }
    }
  }

  .rt-tbody {
    margin-bottom: 0;
    position: relative;
  }
}

.ReactTable.CompareMetricsTableTable {
  .-headerGroups {
    .rt-th {
      border-right: 1px dashed $gray-6;
      padding-top: 5px;
      &:last-child {
        border-right: none;
      }
    }
  }
  .rt-tbody {
    border-top: 1px solid $gray-2;
  }
  .rt-th,
  .rt-td {
    padding-left: 1.25rem;
    &:nth-child(1),
    &:nth-child(4),
    &:nth-child(7) {
      border-right: 1px dashed $gray-6;
    }
  }
}

.table-favorite-scenarios {
  .rt-tbody {
    border: none;
    .rt-tr-group {
      margin-bottom: 1.875rem;
      border: 1px solid $gray-2;
      &:first-child {
        border-top: none;
      }
      &:last-child {
        border-bottom: 1px solid $gray-2;
      }
    }
  }
  .rthead {
    &.-filters {
      border-bottom: none !important;
    }
  }
}
