@import "../../../../variables.scss";

.ScenarioMainContainer {
  display: flex;
}

.Header {
  display: flex;
  margin: 1rem 0 1.188rem;
  justify-content: flex-end;
  align-items: center;
}

.TestsNumberContainer {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TestsNumber {
  font-size: 3.75rem;
  font-family: $font-family-bold;
  margin-right: 1.785rem;
}

.Testsdescription {
  font-size: 1.2rem;
  color: $gray-6;
}

.ScenarioContainer {
  margin-left: 1.875rem;
  flex-grow: 1;
}

.DateRangePickerContainer {
  display: flex;
  margin-bottom: 10px;
}

.Content {
  display: grid;
  grid-gap: 0.25rem 1.25rem;
  grid-template-columns: repeat(4, 1fr);
}

.ChartContainer {
  margin-bottom: 10px;

  hr {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  span {
    width: auto;
  }
}

.ScalabilityModelWrapper {
  height: 500px;
  .recharts-responsive-container {
    color: red;
  }
}
