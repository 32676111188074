@import "../../../../../globals.module.scss";
@import "../../../../../variables.scss";

.NavItemsList {
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin: 0;
  padding-left: 3.75rem;
  height: 100%;
}

.NavItemsList li {
  height: 100%;
  margin-right: 3.5rem;

  &:hover {
    a {
      color: $gold;
    }
  }
}

.NavButton {
  color: $white;
  margin-right: 1rem;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  height: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  padding: 0 10px;

  &:hover {
    border-bottom: 2px solid $gold;
    a {
      color: $gold;
    }
  }
}

.Active {
  border-bottom: 2px solid $gold;
  color: $gold;
}
