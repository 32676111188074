@import "../../../variables.scss";

.TableContainer {
  width: 100%;
}

.FormContainer {    
  display: flex;
  width: 100%;
  align-items: center;

  &>input, .InputGroup {
    position: relative;
    width: 30%;
    margin-right: 1rem;
  }
}

.Container {
  display: flex;
}

.ErrorMessage {
  color: $red;
  padding: 0 10px;
  text-align: center;
}

.TogglePassword {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 31%;
  right: 0;
  cursor: pointer;
  transform: translate(0, -50%);
  padding: 10px;
  color: gray;
  z-index: 5;
  &:hover:enabled {
    color: $primary-color;
  }
  &:focus {
    outline: none;
  }
}

.btnClient{
  height: 2.5rem;
  margin-bottom: 20px;
}