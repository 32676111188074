@import "./variables.scss";

.Bold {
  font-weight: 600;
}

.MainContent {
  padding: 1.875rem;
  width: 100%;
  height: calc(100vh - 4.375rem);
  overflow: auto;
  @include small {
    width: 100%;
    margin: 0;
    padding: 65px 10px;
  }
}

.RouterLink {
  width: 100%;
  height: 100%;
  padding: 0.5rem 1rem;
  display: block;
  color: white;
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: none;
    color: $black;
    background-color: $gray-2;
  }
  @media (max-width: 803px) {
    flex: 1;
  }
}
