@import "../../../../../../variables.scss";

.createTestStepper {
  border: none;
  box-shadow: none;

  header.stepper__header {
    height: 40px;
  }

  .stepper__head {
    height: 40px;
  }

  span.stepper__head__title {
    width: unset;
  }

  form.stepper__content {
    padding: 15px 0px 0 0px;
  }

  button.stepper__head--active {
    span.stepper__head__index {
      background: $primary-color;
    }
  }

  button.stepper__action {
    span {
      width: unset;
    }
  }

  span.stepper__head__icon {
    height: auto;
  }
  span.mdc-linear-progress__bar-inner {
    background: $primary-color;
  }

  .StepperBackButton {
    background: rgb(168, 168, 168);
    border-color: rgb(168, 168, 168);

    &:disabled {
      background: rgb(214, 214, 214);
      border-color: rgb(214, 214, 214);
      cursor: auto;
    }
  }

  .StepperCancelButton {
    margin: 0;
    margin-right: 12px;
    margin-left: auto;
  }
}
