@import "../../../../variables.scss";

.CheckBox {
  border: 1px solid $gray-3;
  // border-radius: 5px;
  width: 1.25rem;
  height: 1.25rem;
  transition: all 0.2s ease;

  &:hover {
    border-color: $red;
  }
}

.CheckBox_checked,
.CheckBox_hover {
  border-color: $red;
}

.Check {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $red;

  i {
    margin-left: 0;
    margin-top: 1px;
    display: inline-flex;
    font-size: $font-size-100;
  }
}
