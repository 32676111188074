@import "../../../../../../variables.scss";

.Card {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $font-size-400;
  background: $white;
  padding: 0.625rem;
  box-shadow: $standard-shadow;
  min-height: 6.875rem;
}

.Title {
  font-size: $font-size-300;
  color: $gray-6;
  margin: 0 0.938rem;
}

.Content {
  display: flex;
  align-items: center;
  margin: 0 0.938rem;
}

.Arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;

  span {
    font-size: 2.125rem;
    margin-right: 0.3rem;
    margin-left: 0;
  }
}

.Value {
  font-size: 2.813rem;
  white-space: nowrap;
  font-family: $font-family-bold;
}

.Percentage {
  font-size: 1.813rem;
  font-family: $font-family-bold;
}

.NoData {
  height: 100%;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}
