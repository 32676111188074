@import "../../../../../variables.scss";

.HeatMap {
  min-height: 500px;
  svg > g > g:nth-child(3) > g > text {
    font-size: 18px !important;
  }

  text {
    font-size: 16px !important;
    font-family: inherit !important;
  }
}

.Wrapper {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #c9c9c9;
    border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #b4b4b4;
  }
}

.FilterContainer {
  display: flex;
  align-items: center;

  margin-bottom: 1.25rem;
}

.FilterInput,
.form-control {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  width: 40% !important;
  min-width: 300px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background: $white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  margin-bottom: 0 !important;
}

.CheckBoxContainer {
  margin-left: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  color: $gray-6;

  span {
    font-size: $font-size-050;
    margin-left: 0.625rem;
    display: block;
  }
}
