@import "../../../../../../variables.scss";

.Wrapper {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.625rem 1.25rem;
  margin-bottom: 0.625rem;
  cursor: pointer;

  &:hover {
    background: $gray-1;
  }
}

.Container {
  display: flex;
  align-items: center;
  border-radius: 5px;

  span {
    width: unset;
  }

  &:hover {
    .CheckBox {
      border: 1px solid $primary-color;
    }
  }
}

.DeleteIcon {
  font-size: 13px;
  position: absolute;
  right: 4px;
  cursor: pointer;

  &:hover {
    color: $red;
  }
}

.Content {
  display: flex;
  align-items: flex-end;
}

.Label {
  font-size: $font-size-200;
  color: $black;
  max-width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 0.5rem;
}

.FieldName {
  font-size: $font-size-050;
  max-width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.CheckBoxContainer {
  margin-right: 6px;
}
