@import "../../../variables.scss";

.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 15vh;
}

.code {
  font-size: 20vh;
  line-height: 15vh;
}

.message {
  font-size: 3vh;
  margin-bottom: 20px;
}

.big {
  font-size: 30vh;
  line-height: 0;
  color: $primary-color;

  span {
    margin: 0;
  }
}

.icon {
  position: relative;
  margin-bottom: 30px;
}

.times {
  position: absolute;
  font-size: 15vh;
  top: 15vh;
  right: 0vh;
  color: $secondary-color;

  span {
    margin: 0;
  }
}
