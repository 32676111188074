.UploadedFilesContainer {
  padding: 5px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.UploadedFilesWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    height: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #848484;
    border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgb(121, 121, 121);
  }
}

.DropzoneText {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
