@import "../../../variables.scss";

.BtnCreateAccount {  
  cursor: pointer;
  color: $gold-2;
  flex: 0 0 100%;
  width: 100%;
  background-color: transparent;
  border: 1px solid transparent;
  margin-top: 1em;
  font-family: $font-family-bold;
  font-size: $font-size-200;

  &:focus {
    outline: 0;
  }
}

.alertDanger {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alertWarning {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

form > div > input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
