.Container {
  background: #fff;
  border: 1px solid #d3dae6;
  padding: 5px;
  padding-top: 10px;
  border-radius: 4px;
  box-shadow: rgba($color: #000000, $alpha: 0.25) 0px 1px 2px;
  text-align: center;
}

.ContentContainer {
  padding: 0px 5px;
  padding-top: 5px;
  margin: 0 5px;
}

.Title {
  line-height: 12px;
  font-size: 16px;
  font-weight: bold;
}

.Value {
  font-size: 20px;
  font-weight: bold;
}

.ScenariosContainer {
  display: flex;
}

.DiffContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    margin: 0;
    margin-right: 5px;
    font-size: 20px;
  }
}
