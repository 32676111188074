@import "../../../../../../variables.scss";

.AddField {
  background: $gray-1;
  padding: 0.625rem 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  i {
    margin-right: 5px;
    margin-left: 0;
    font-size: 18px;
    cursor: pointer;
  }

  span {
    width: unset;
    cursor: pointer;
  }
}

.AddFieldButton {
  display: inline-block;
  border: 1px solid;
  font-family: $font-family-bold;
  padding: 0 1.875rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NewFieldForm {
  position: absolute;
  background: $gray-1;
  padding: 0.625rem 1.25rem;
  width: 100%;
  top: 100%;
  left: 0;
  overflow: hidden;
  z-index: 100000;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-5px);
  text-align: center;

  input[type="text"],
  input {
    display: block;
    width: 100%;
    padding: 5px 8px;
    font-size: $font-size-050;
    line-height: 1;
    margin-bottom: 0.625rem;
    background-color: $white !important;

    &:focus {
      border: 1px solid $primary-color;
      outline: 0;
      background-color: $white !important;
    }
  }
}

.NewFieldFormOpened {
  transition: all 0.2s ease-in-out;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.NewFieldSaveButton {
  min-width: 50px !important;
}

.DisabledButton {
  background: rgb(235, 235, 235);
}
